
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.main-banner-inner {
    width: 100%;
    max-width: 1280px;
    padding-right: 0;
    padding-left: 0;
    @media screen and (min-width: 1024px) {
        padding-right: 26px;
        padding-left: 26px;
    }
}
.image {
    width: 100%;
    max-width: 50%;
    display: none;
    @media screen and (min-width: 1024px) {
        display: block;
    }
}

.main-banner {
    width: 100vw;
    min-height: calc(100vh - 58px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding: 0 10px;
    // padding-bottom: 200px;
    background-image: url("/assets/images/main_banner_imageOpt.png");
    background-position: bottom right;
    padding-bottom: 200px;
    background-size: 500px;
    background-repeat: no-repeat;
    background-color: #e2f2ff;
    @media screen and (min-width: 1024px) {
        padding-bottom: 0;

        background-position: center right;
        background-size: 400px;
    }
    @media screen and (min-width: 1280px) {
        background-size: 630px;
    }

    // @include xl {
    //     padding-bottom: 100px;
    // }

    .form {
        z-index: 5;
        padding: 30px 24px;
        width: auto;
        // max-width: 630px;
        border-radius: 20px;
        background-color: white;
        box-shadow: 0px 4px 23px rgba(0, 0, 0, 0.35);
        border-radius: 5px;
        min-height: 500px;
        display: flex;
        flex-direction: column;
        position: relative;
        margin-top: 6vh;
        @media screen and (min-width: 1024px) {
            width: 630px;
        }
        .steps-inner {
            flex-grow: 1;
        }
        h1 {
            &.step-title {
                font-size: 20px;
                font-weight: bold !important;
                margin: 0;
                @include lg {
                    font-size: 30px;
                }
            }
        }
        .step-title {
            text-align: center;
            font-size: 28px !important;
            font-weight: 500 !important;
            margin-bottom: 10px;
        }
        .label-as-step-title {
            text-align: center;
            font-weight: 500 !important;
            margin-bottom: 10px;
            margin-top: -20px;
            margin-bottom: 25px !important;
            font-size: 28px !important;
            @include lg {
                margin-left: -50px;
                margin-right: -50px;
            }
        }
        .step-description {
            text-align: center;
            font-weight: 300;
            margin-bottom: 2rem;
            font-size: 14px;
            @include lg {
                font-size: 18px;
            }
        }
        .fields-wrapper {
            margin-top: 1.5rem;
        }
        .form-button {
            max-width: unset !important;
            margin: 0 auto !important;
            margin-top: 5px !important;
            border-radius: 3px !important;
            border-radius: 5px !important;
            background: linear-gradient(180deg, #ffb648 0%, #f7941d 100%);
            color: black;
            transition: all 0.2s ease-in-out;
            @include lg {
                max-width: 416px !important;
            }
        }
        .form-field {
            // margin-top: 2rem;
            max-width: unset;
            margin: 0 auto;
            margin-bottom: 0.5rem;
            @include lg {
                max-width: 416px;
            }
            > input {
                border-radius: 5px !important;
            }
        }
        .radio-field {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            margin-top: 10px;
            & > div {
                width: 100%;
                margin-bottom: 15px;
                display: flex;
                input {
                    &:checked + label {
                        border: 2px solid black;
                    }
                }
                label {
                    border-radius: 5px !important;
                    background: linear-gradient(
                        180deg,
                        #ffb648 0%,
                        #f7941d 100%
                    );
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    flex-direction: row;
                    min-height: 50px;
                    font-weight: 500;

                    & > span {
                        margin-top: 0;
                    }
                    & > div {
                        margin-right: 20px;
                        margin-left: 20px;
                        img {
                            width: 100%;
                        }
                    }
                    &:hover {
                        filter: brightness(1.1);
                    }
                    @include md {
                        > span {
                            @apply text-lg;
                        }
                        min-height: 62px;
                    }
                    @apply text-left;
                    svg {
                        margin-bottom: 10px;
                        width: 30px;
                        height: auto;
                    }
                }
                @include lg {
                    margin: 0 10px;
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
            @include lg {
                margin: 0 -10px;
            }
        }
        .tcpa {
            margin-bottom: 10px;
        }
    }

    // &::before {
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     background-size: cover;
    //     background-repeat: no-repeat;
    //     background-position: bottom;
    //     background-image: url("/assets/images/mobile.jpg");

    //     @include md {
    //         background-image: url("/assets/images/background.jpg");
    //     }
    // }
}

// .main-banner {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     gap: 50px;
//     align-items: center;
//     @include md {
//         flex-direction: row;
//     }
//     min-height: calc(100vh - 50px);

//     .form {
//         box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.17);
//         background-color: #fff;
//         border-radius: 10px;
//         z-index: 5;
//         width: 100%;
//         padding: 30px 24px;
//         max-width: 550px;
//         min-height: 500px;
//         display: flex;
//         flex-direction: column;
//         position: relative;

//         @media screen and (max-width: 600px) {
//             margin-top: 3vh;
//         }

//         @include lg {
//             padding: 40px;

//             @media screen and (max-width: 1200px) {
//                 max-width: 500px;
//             }
//         }

//         .steps-inner {
//             flex-grow: 1;
//         }
//         h1 {
//             &.step-title {
//                 font-size: 20px;
//                 font-weight: bold !important;
//                 margin: 0;
//                 @include lg {
//                     font-size: 30px;
//                 }
//             }
//         }
//         .step-title {
//             text-align: center;
//             font-size: 20px !important;
//             font-weight: 700 !important;
//             margin-bottom: 10px;
//         }
//         .label-as-step-title {
//             text-align: center;
//             font-size: 20px !important;
//             font-weight: 700 !important;
//             margin-bottom: 10px;
//             margin-top: -20px;
//             margin-bottom: 25px !important;
//         }
//         .step-description {
//             text-align: center;
//             font-weight: 300;
//             margin-bottom: 2rem;
//             font-size: 14px;
//             @include lg {
//                 font-size: 18px;
//             }
//         }
//         .fields-wrapper {
//             margin-top: 1.5rem;
//         }
//         .form-button {
//             max-width: unset !important;
//             margin: 0 auto !important;
//             margin-top: 5px !important;
//             border-radius: 3px !important;
//             background-color: $wideformatprintercosts-pink !important;
//             transition: all 0.2s ease-in-out;
//             @include lg {
//                 max-width: 450px !important;
//             }
//             &:hover {
//                 background-color: $wideformatprintercosts-pink !important;
//             }
//         }
//         .form-field {
//             // margin-top: 2rem;
//             max-width: unset;
//             margin: 0 auto;
//             margin-bottom: 0.5rem;
//             @include lg {
//                 max-width: 450px;
//             }
//         }
//         .radio-field {
//             display: flex;
//             flex-direction: row;
//             flex-wrap: wrap;
//             justify-content: center;

//             margin-top: 10px;
//             & > div {
//                 width: 100%;
//                 margin-bottom: 15px;
//                 display: flex;

//                 input {
//                     &:checked + label {
//                         border: 2px solid $wideformatprintercosts-pink;
//                         background-color: $wideformatprintercosts-hover-bg;
//                         color: #000;
//                     }
//                 }
//                 label {
//                     border-radius: 3px;
//                     border: 2px solid $wideformatprintercosts-blue;
//                     background-color: #fff;
//                     color: #000;
//                     font-size: 14px;
//                     display: flex;
//                     align-items: center;
//                     flex-direction: row;
//                     @media screen and (max-width: 767px) {
//                         padding: 10px 5px;
//                     }
//                     &:hover {
//                         border: 2px solid $wideformatprintercosts-pink;
//                         background-color: $wideformatprintercosts-hover-bg;
//                         color: #000;
//                     }
//                     span {
//                         font-size: 16px !important;
//                         @media screen and (max-width: 767px) {
//                             font-size: 15px !important;
//                         }
//                     }
//                     & > div {
//                         margin-right: 15px;

//                         & + span {
//                             margin-top: 0;
//                             margin-right: inherit;
//                             width: auto;
//                         }
//                     }
//                     & > span {
//                         margin-right: auto;
//                         width: 100%;
//                     }

//                     @include md {
//                         min-height: 58px;
//                     }
//                     svg {
//                         margin-bottom: 10px;
//                         width: 30px;
//                         height: auto;
//                     }
//                 }
//                 @include lg {
//                     margin: 0 10px;
//                     margin-bottom: 15px;
//                     width: 100%;
//                 }
//             }
//             @include lg {
//                 margin: 0 -10px;
//             }
//         }
//         .tcpa {
//             margin-bottom: 10px;
//         }
//     }
// }

.step-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 345px;
}
.back-button {
    left: 40px;
    @media screen and (max-width: 1023px) {
        left: 25px;
    }
    @media screen and (max-width: 414px) {
        left: 0;
    }
}
